import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import tachyons from 'tachyons-components'
import Layout from '../components/Layout'
import { globalHistory } from '@reach/router'
import Content, { HTMLContent } from '../components/Content'
import SEO from '../components/Seo'
import { format } from 'date-fns'
import GlobalStyle from "../styles/GlobalStyle"

const ArticleContainer = tachyons('article')`
mb5 pv4-ns
`
const TitleWrapper = tachyons('div')`
wrap-nav tc pb5
`
const ArticleTitle = tachyons('h1')`
f3 f2-ns fw6 tc db pt5 pt6-ns mt0 mb2 lh-title
`
const ContentContainer = tachyons('div')`
wrap mw12
`
const PostContainer = tachyons('div')`
center measure-wide lh-copy post
`
const ArticleDate = styled('small')`
color: var(--textPrimary);
`
export const SinglePostTemplate = ({
  content,
  contentComponent,
  title,
  description,
  date,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <ArticleContainer>
      <GlobalStyle/>
      {helmet || ''}
      <SEO
        title={`${title} · Ajmal Afif`}
        desc={description}
        node={content}
        article>
        <meta name="description" content={description} />
        <meta name="twitter:title" content={`${title} · Ajmal Afif`} />
        <meta name="twitter:description" content={description} />
        <meta property="og:title" content={`${title} · Ajmal Afif`} />
        <meta property="og:description" content={description} />
      </SEO>
      <TitleWrapper>
      <ArticleTitle>
        {title}
      </ArticleTitle>
      <ArticleDate>{date}</ArticleDate>
      </TitleWrapper>
      {/* <p>{description}</p> */}
      <ContentContainer>
        <PostContainer>
          <PostContent content={content} />
        </PostContainer>
      </ContentContainer>
    </ArticleContainer>
  )
}

SinglePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
}

const SinglePost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SinglePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet
            titleTemplate="%s"
            htmlAttributes={{
                class: 'blog single'
            }}
          >
            <title>{`${post.frontmatter.title} · Ajmal Afif`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
            <meta property="og:type" content="article" />
            <script type="application/ld+json">
            {JSON.stringify(
              {
                '@context': 'http://schema.org',
                '@type': 'Article',
                mainEntityOfPage: 'https://ajmalafif.com',
                url: `https://ajmalafif.com${globalHistory.location.pathname}`,
                datePublished: `${post.frontmatter.date}`,
                dateModified: Date(),
                name: `${post.frontmatter.title}`,
                headline: `${post.frontmatter.title}`,
                description: `${post.frontmatter.description}`,
                image: {
                  '@type': 'ImageObject',
                  url: '/img/og-image-square.png',
                },
                author: {
                  '@type': 'Person',
                  name: 'Ajmal Afif',
                },
                copyrightHolder: {
                  '@type': 'Person',
                  name: 'Ajmal Afif',
                },
                copyrightYear: `${format(new Date(), 'yyyy')}`,
                creator: {
                  '@type': 'Person',
                  name: 'Ajmal Afif',
                },
                publisher: {
                  '@type': 'Person',
                  name: 'Ajmal Afif',
                  logo: {
                    '@type': 'ImageObject',
                    url: '/img/og-image-square.png',
                  },
                },
              }
            )
            }
            </script>
          </Helmet>
        }
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

SinglePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default SinglePost

export const pageQuery = graphql`
  query SinglePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
